@import 'base';
@import url(https://fonts.google.com/specimen/Poppins);

.App {
    box-sizing: border-box;
    background: url('../images/bg-intro-desktop.png') no-repeat center center / cover;
    background-color: $Red;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(280px, 550px) minmax(320px, 550px);
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 50px;

    * {
        font-family: 'Poppins', sans-serif;
        transition: 0.5s;
    }

    .intro {
        padding-left: 10px;  
        padding-right: 10px;

        * {
            margin: 30px;
        }

        h1 {
            font-size: 50px;
            line-height: 50px;
            color: white;
        }

        p {
            font-size: 17px;
            line-height: 20px;
            color: white;
        }
    }

    .signup-cont {
        display: grid;
        gap: 30px;
        grid-template-rows: auto auto;
        margin-right: 30px;
        margin-left: 30px;

        .add {
            background-color: $Blue;
            box-shadow: 0 10px 5px rgba(0, 0, 0, 0.4);
            padding: 20px;
            border-radius: 20px;
            color: white;
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
}

.bold {
    font-weight: bold;
}


@media (max-width: 780px) {
    .App {
        background: url('../images/bg-intro-mobile.png') no-repeat center center / cover;
        background-color: $Red;
        width: 100%;
        height: 100%;
        display: grid;
        gap: 45px;
        grid-template-columns: minmax(320px, 550px);
        grid-template-rows: auto auto;
        padding: 20px;
        justify-items: center;

        * {
            font-family: 'Poppins', sans-serif;
            transition: 0.5s;
        }

        .intro {
            margin-top: 0px;
            padding-left: 0;
            padding-right: 0;
            text-align: center;

            * {
                margin: 10px;
            }

            h1 {
                font-size: 50px;
                line-height: 50px;
                color: white;
            }

            p {
                font-size: 17px;
                line-height: 20px;
                color: white;
            }
        }

        .signup-cont {
            display: grid;
            gap: 30px;
            grid-template-columns: minmax(320px, 550px);
            grid-template-rows: auto auto;
            margin-right: 30px;
            margin-left: 30px;
    
            .add {
                background-color: $Blue;
                box-shadow: 0 10px 5px rgba(0, 0, 0, 0.4);
                padding: 20px;
                border-radius: 20px;
                color: white;
                display: flex;
                justify-content: center;
                width: 100%;
            }
        }
    }
}