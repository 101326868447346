@import 'base';

.signup-field {
    display: grid;
    grid-template-rows: repeat(5, 1fr auto);
    background-color: #fff;
    box-shadow: 0 10px 5px rgba(0, 0, 0, 0.4);
    padding: 20px;
    border-radius: 15px;

    .inputCont {    
        position: relative;;
        .exMark {
            display: none;
        }

        .exMark.error {
            position: absolute;
            right: 15px;
            top: 14px;
            display: block;
        }

        input {
            width: 100%;
            height: 40px;
            padding: 25px;
            font-weight: 400;
            border: $Grayish-blue 2px solid;
            border-radius: 5px;
        }

        input.error {
            border: $Red 2px solid;
        }

        input::placeholder {
            font-weight: bold;
        }
    }

    .warning {
        margin: 0;
        font-size: 11px;
        font-weight: 800;
        margin-bottom: 15px;
        margin-top: 2px;
        justify-self: right;
        color: $Red;
        visibility: hidden;
    }

    .warning.error {
        visibility: visible;
    }

    button {
        background-color: $Green;
        color: white;
        font-size: 15px;
        text-transform: uppercase;
        display: inline-block;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        transition: transform 0.3s;
    }

    button:hover {
        transform: translateY(-2px);
        background-color: #00d785;
    }

    button:active {
        background-color: #41ffb6;
        transform: translateY(2px);
    }


    .disclaimer {
        margin-top: 8px;
        font-size: 12px;
        justify-self: center;
        color: $Grayish-blue;

        a {
            color: $Red;
        }
    }
}